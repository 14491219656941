/* eslint-disable max-len*/
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { appRoutePaths } from 'src/services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import { DataEntryMode } from 'src/types/DataEntryMode';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import StyledSingleDropdown from '../../../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectSchoolYear,
  selectStudentInterventionListItems,
  selectStudentInterventionLoadingListItems,
  setSchoolYearFilter,
  setStudentInterventionDataEntryMode,
} from '../../../../redux/slices/studentInterventionSlice';
import { fetchStudentInterventionListItems } from '../../../../redux/thunks/studentInterventionThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { FormDropdownSelectOption } from '../../../../types/propTypes/FormDropdownPropTypes';
import { ReactSelectOption } from '../../../../types/ReactSelectOption';
import { getSchoolYearList } from '../../../../types/SchoolYearListModel';
import { StudentInterventionListItemModel } from '../../../../types/StudentInterventionListItemModel';
import { isStateNonAdmin } from '../../../../utilities/userUtilities';
import StudentPage from '../StudentPage';
import './StudentInterventionList.css';

const StudentInterventionList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const schoolYear = useAppSelector(selectSchoolYear);
  const isLoading = useAppSelector(selectStudentInterventionLoadingListItems);
  const listItems: StudentInterventionListItemModel[] = useAppSelector(
    selectStudentInterventionListItems
  );

  const selectedOption = schoolYear
    ? { value: schoolYear, label: schoolYear }
    : {
        value: getSchoolYearList()[0].value,
        label: getSchoolYearList()[0].value,
      };

  const currentSchoolYear = getSchoolYearList()[0].value;

  useEffect(() => {
    if (schoolYear === '') {
      dispatch(setSchoolYearFilter(getSchoolYearList()[0].value));
    }
  }, [dispatch, schoolYear]);

  useEffect(() => {
    let promise: unknown;
    if (schoolYear) {
      promise = dispatch(fetchStudentInterventionListItems(schoolYear));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, schoolYear]);

  const handleOnChange = async (
    option: FormDropdownSelectOption
  ): Promise<void> => {
    const selectedValue = (option as SingleValue<ReactSelectOption>)?.value;
    selectedValue && dispatch(setSchoolYearFilter(selectedValue));
  };

  const handleEdit = (studentInterventionId: string): void => {
    navigate(appRoutePaths.StudentInterventionInput(studentInterventionId));
  };

  const handleNew = (): void => {
    dispatch(setStudentInterventionDataEntryMode(DataEntryMode.NEW));
    navigate(appRoutePaths.StudentInterventionInput('new'));
  };

  const canAddNew = canUserAccessRoute(
      appRoutePaths.StudentInterventionInput()
    );

  return (
    <StudentPage
      pageClass="data-input--student--student-intervention--list"
      isLoading={isLoading}
      loadingDataId={'student-intervention-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div
            className={
              isStateNonAdmin()
                ? 'school-year-selection-container school-year-padding'
                : 'school-year-selection-container'
            }
          >
            <StyledSingleDropdown
              fieldName="schoolYear"
              options={getSchoolYearList()}
              onChange={handleOnChange}
              value={selectedOption}
            />
          </div>
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>Student Intervention</h4>
            {currentSchoolYear 
              && currentSchoolYear == schoolYear 
              && canAddNew 
              && !isStateNonAdmin() && (
            <ActionButton
              onClick={handleNew}
              classes="no-wrap-text new-button"
              tooltipText="Add New Student Intervention"
            >
              <>Add New</>
            </ActionButton>
            )}
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>MSIS ID</th>
                  <th>LEA</th>
                  <th>Grade</th>
                  <th>Referral</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((studentIntervention) => (
                    <tr
                      key={`${studentIntervention.id}`}
                      data-testid={studentIntervention.id}
                    >
                      <td>{studentIntervention.lastName}</td>
                      <td>{studentIntervention.firstName}</td>
                      <td>{studentIntervention.msisId}</td>
                      <td>{studentIntervention.lea}</td>
                      <td>{studentIntervention.grade}</td>
                      <td>
                        {studentIntervention.interventionCriteria ===
                        '*6 Referral Studet'
                          ? 'Y'
                          : 'N'}
                      </td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${studentIntervention.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(studentIntervention.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'View/Edit Student'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StudentPage>
  );
};

export default StudentInterventionList;
