/* eslint-disable max-len */
import { ReactElement } from 'react';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import { useAppSelector } from '../../../../redux/hooks';
import { StudentInterventionValidationSchema } from 'src/types/Validation/StudentInterventionValidationSchema';
import {
  clearStudentIntervention,
  saveStudentIntervention,
  selectStudentInterventionDataEntryMode,
  selectStudentInterventionFormViewModel,
  selectStudentInterventionLoadingFormViewModel,
  selectStudentInterventionLoadingStudentIntervention,
  selectStudentInterventionStudentIntervention,
  selectSchoolYear,
  setStudentInterventionDataEntryMode,
} from '../../../../redux/slices/studentInterventionSlice';
import {
  fetchStudentInterventionById,
  fetchStudentInterventionFormViewModel,
} from '../../../../redux/thunks/studentInterventionThunks';
import {
  getCurrentUserAccess,
} from '../../../../services/base.service';
import {
  createStudentIntervention,
  updateStudentIntervention,
} from '../../../../services/studentIntervention.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_STUDENT } from '../../../../types/NavigationTabs';
import { StudentInterventionModel } from '../../../../types/StudentInterventionModel';
import { StudentInterventionFormViewModel } from '../../../../types/StudentInterventionFormViewModel';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import { formatDistrictForDisplay } from '../../../../utilities/textUtilities';
import StudentIntervention_Layout from 'src/components/FormLayouts/StudentIntervention/StudentIntervention_Layout';

const StudentInterventionInput = (): ReactElement => {
  const model: StudentInterventionModel = useAppSelector(
    selectStudentInterventionStudentIntervention
  );
  const formViewModel: StudentInterventionFormViewModel = useAppSelector(
    selectStudentInterventionFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectStudentInterventionLoadingFormViewModel
  );
  const isLoadingModel = useAppSelector(
    selectStudentInterventionLoadingStudentIntervention
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectStudentInterventionDataEntryMode
  );
  const schoolYear = useAppSelector(selectSchoolYear);
  const isLoading = isLoadingModel || isLoadingFormViewModel;

  const getDistrictDisplay = (): string => {
    if (dataEntryMode === DataEntryMode.EDIT) {
      return model.districtName;
    } else {
      const user = getCurrentUserAccess();
      return formatDistrictForDisplay(user?.currentDistrict);
    }
  };

  const getSchoolYear = (): string => {
    if (dataEntryMode == DataEntryMode.EDIT) {
      return model.schoolYear;
    } else {
      // If redux is filled use that, otherwise use the default from the form model
      if (schoolYear !== '') {
        return schoolYear;
      } else {
        return formViewModel.defaultSchoolYear;
      }
    }
  };

  const getSchoolDisplay = (): string => {
    return dataEntryMode === DataEntryMode.EDIT
      ? `${model.schoolName} (${model.schoolId})`
      : '';
  };

  const intitalValues: StudentInterventionModel = {
    ...model,
    districtName: getDistrictDisplay(),
    schoolYear: getSchoolYear(),
    schoolName: getSchoolDisplay(),
  };

  return (
    <ManualInputPage<StudentInterventionModel, StudentInterventionFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'student-intervention-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_STUDENT)}
      classes={{
        pageClass: 'data-input--student-intervention--input',
      }}
      parameterOptions={{
        routeParameterName: 'studentInterventionId',
        identityName: model.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.StudentInterventionList,
        cancelRoute: appRoutePaths.StudentInterventionList,
        createSuccessRoute: appRoutePaths.StudentInterventionInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchStudentInterventionById,
        loadFormDetail: fetchStudentInterventionFormViewModel,
        setEntryMode: setStudentInterventionDataEntryMode,
        setData: saveStudentIntervention,
        clearData: clearStudentIntervention,
        onCreate: createStudentIntervention,
        onUpdate: updateStudentIntervention,
      }}
      formDetails={{
        validationSchema: StudentInterventionValidationSchema,
        initialFormValues: intitalValues,
        isFormModelEmpty: true,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'student intervention',
        pageHeader: 'Student',
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
      REDIRECT_TO_LIST_PAGE={true}
    >
      <StudentIntervention_Layout />
    </ManualInputPage>
  );
};

export default StudentInterventionInput;
