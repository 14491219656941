export enum FeatureAccessPermissions {
  ACCREDITATION_ACCESS = 'AccreditationAccess',
  APPLICATION_ACCESS = 'ApplicationAccess',
  BOARD_MEMBERS_ACCESS= 'BoardMembersAccess',
  CERTIFICATION_ACCESS = 'CertificationAccess',
  CHANGE_DISTRICT_ACCESS = 'ChangeDistrictAccess',
  CHARTER_SCHOOL_ACCESS = 'CharterSchoolAccess',
  COURSE_CODE_ACCESS = 'CourseCodeAccess',
  DATA_QUALITY_ACCESS = 'DataQualityAccess',
  DISTRICT_SHARING_ACCESS = 'DistrictSharingAccess',
  FILE_STATUS_ACCESS = 'FileStatusAccess',
  FILE_UPLOAD_ACCESS = 'FileUploadAccess',
  LEA_MANAGEMENT_ACCESS = 'LeaManagementAccess',
  MSIS_ID_ACCESS = 'MsisIdAccess',
  NON_PUBLIC_STUDENT_ACCESS = 'NonPublicStudentAccess',
  REPORT_ACCESS = 'ReportAccess',
  SCHOOL_MANAGEMENT_ACCESS = 'SchoolManagementAccess',
  SEA_ORGANIZATION_ACCESS = 'SeaOrganizationAccess',
  STUDENT_OWNERSHIP_ACCESS = 'StudentOwnershipAccess',
  SUMMER_ACTIVITY_ACCESS = 'SummerActivityAccess',
  USER_MANAGEMENT_ACCESS = 'UserManagementAccess',
  SALARY_SCALE_ACCESS = 'SalaryScaleAccess',
  STUDENT_INTERVENTION_ACCESS = 'StudentInterventionAccess',
  
}
