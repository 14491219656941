import { createAsyncThunk } from '@reduxjs/toolkit';
import { BoardMembersFormViewModel } from '../../types/BoardMembersFormViewModel';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getMinBoardMembersListItems,
  getBoardMembersFormViewModel,
  getBoardMembersListItems,
  putAllBoardMembersListItems,
} from '../../services/boardMembers.service';
import { BoardMembersListItemModel } from '../../types/BoardMembersListItemModel';

export const fetchBoardMembersListItems = createAsyncThunk(
  'input/fetchBoardMembersListItems',
  async (schoolYear: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      BoardMembersListItemModel[]
    >(
      thunkAPI,
      getBoardMembersListItems(schoolYear, { signal: thunkAPI.signal })
    );
  }
);

export const fetchMinBoardMembersListItems = createAsyncThunk(
  'input/fetchMinBoardMembersListItems',
  async (schoolYear: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      BoardMembersListItemModel[]
    >(
      thunkAPI,
      getMinBoardMembersListItems(schoolYear, { signal: thunkAPI.signal })
    );
  }
);

export const updateAllBoardMembersListItems = createAsyncThunk(
  'update/allBoardMembersListItems',
  async (
    params: { schoolYear: string; additionalSalary: string },
    thunkAPI
  ) => {
    const { schoolYear, additionalSalary } = params;
    return await thunkExecuteAndReturnResultOrShowError<
      BoardMembersListItemModel[]
    >(
      thunkAPI,
      putAllBoardMembersListItems(schoolYear, additionalSalary, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchBoardMembersFormViewModel = createAsyncThunk(
  'input/fetchBoardMembersFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<BoardMembersFormViewModel>(
      thunkAPI,
      getBoardMembersFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
