import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const StudentInterventionValidationSchema = yup.object().shape({
  msisId: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .mustBeNumber()
    .min(9, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(9))
    .max(9, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(9)),
  lastName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  firstName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  dateOfBirth: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  districtName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  districtId: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .mustBeNumber()
    .min(4, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(4))
    .max(4, VALIDATION_MESSAGE.AT_MOST_CHARS(4)),
  schoolYear: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  schoolId: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  schoolName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  grade: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  gender: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  ethnicity: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  race: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  interventionCriteria: yup.array().required(VALIDATION_MESSAGE.REQUIRED),
  initialReferralInterventionDate: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  numberOfSuspensionDays: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
});
