/* eslint-disable max-len*/
import { ReactElement, useEffect } from 'react';
import { SingleValue } from 'react-select';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import StyledSingleDropdown from '../../../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectSchoolYear,
  setSchoolYearFilter,
} from '../../../../redux/slices/boardMembersSlice';
import { TooltipAnchor } from '../../../../services/app.service';
import { FormDropdownSelectOption } from '../../../../types/propTypes/FormDropdownPropTypes';
import { ReactSelectOption } from '../../../../types/ReactSelectOption';
import { getSchoolYearList } from '../../../../types/SchoolYearListModel';
import { StudentInterventionListItemModel } from '../../../../types/StudentInterventionListItemModel';
import { isStateNonAdmin } from '../../../../utilities/userUtilities';
// import StudentPage from '../StudentPage';
import './BoardMembersList.css';
import DistrictSchoolInfoPage from '../DistrictSchoolInfoPage';

const BoardMembersList = (): ReactElement => {
  const dispatch = useAppDispatch();
	// Todo: uncomment when the route for board members input has been added
  // const navigate = useNavigate();
  // Todo: Create selector for school year in board members scope
  const schoolYear = useAppSelector(selectSchoolYear);
  // const isLoading = useAppSelector(selectStudentInterventionLoadingListItems);
  const isLoading = false;
  // const listItems: StudentInterventionListItemModel[] = useAppSelector(
  //   selectStudentInterventionListItems
  // );
  const listItems: StudentInterventionListItemModel[] = [];

  const selectedOption = schoolYear
    ? { value: schoolYear, label: schoolYear }
    : {
        value: getSchoolYearList()[0].value,
        label: getSchoolYearList()[0].value,
      };

  useEffect(() => {
    if (schoolYear === '') {
      dispatch(setSchoolYearFilter(getSchoolYearList()[0].value));
    }
  }, [dispatch, schoolYear]);

  // Todo: Modify dispatch statements when there is a fetch for board members
  // useEffect(() => {
  //   let promise: unknown;
  //   if (schoolYear) {
  //     promise = dispatch(fetchStudentInterventionListItems(schoolYear));
  //   }
  //   return () => {
  //     abortPromiseOnUnmount(promise);
  //   };
  // }, [dispatch, schoolYear]);

  const handleOnChange = async (
    option: FormDropdownSelectOption
  ): Promise<void> => {
    const selectedValue = (option as SingleValue<ReactSelectOption>)?.value;
    selectedValue && dispatch(setSchoolYearFilter(selectedValue));
  };

  // const handleEdit = (studentInterventionId: string): void => {
	// 	// Will correct when the route for Board Members Input is created
  //   // navigate(appRoutePaths.StudentInterventionInput(studentInterventionId));
  // };

  // const handleNew = (): void => {
	// 	// Will correct these when the correct services and slices are created
  //   // dispatch(setStudentInterventionDataEntryMode(DataEntryMode.NEW));
  //   // navigate(appRoutePaths.StudentInterventionInput('new'));
  // };

  // const canAddNew = canUserAccessRoute(
  //     appRoutePaths.StudentInterventionInput()
  //   );

  return (
    <DistrictSchoolInfoPage
      pageClass="data-input--student--student-intervention--list"
      isLoading={isLoading}
      loadingDataId={'board-members-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div
            className={
              isStateNonAdmin()
                ? 'school-year-selection-container school-year-padding'
                : 'school-year-selection-container'
            }
          >
            <StyledSingleDropdown
              fieldName="schoolYear"
              options={getSchoolYearList()}
              onChange={handleOnChange}
              value={selectedOption}
            />
          </div>
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>Board Members</h4>
            { (
            <ActionButton
                onClick={() => {
                  // Placeholder for future functionality
                  console.log('Add New button clicked');
                }}
              classes="no-wrap-text new-button"
              tooltipText="Add New Board Member"
            >
              <>Add New</>
            </ActionButton>
            )}
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Email Address</th>
                  <th>Position Type</th>
                  <th>Term Start</th>
                  <th>Term End</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((studentIntervention) => (
                    <tr
                      key={`${studentIntervention.id}`}
                      data-testid={studentIntervention.id}
                    >
                      <td>{studentIntervention.lastName}</td>
                      <td>{studentIntervention.firstName}</td>
                      <td>{studentIntervention.msisId}</td>
                      <td>{studentIntervention.lea}</td>
                      <td>{studentIntervention.grade}</td>
                      <td>
                        {studentIntervention.interventionCriteria ===
                        '*6 Referral Studet'
                          ? 'Y'
                          : 'N'}
                      </td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${studentIntervention.id}`}
                            aria-label="Edit"
                            onClick={() => {
                              // Placeholder for future functionality
                              console.log('Add Edit button clicked');
                            }}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'View/Edit Student'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DistrictSchoolInfoPage>
  );
};

export default BoardMembersList;
