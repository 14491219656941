/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentInterventionFormViewModel } from 'src/types/StudentInterventionFormViewModel';
import { DataEntryMode } from '../../types/DataEntryMode';
import { StudentInterventionListItemModel } from '../../types/StudentInterventionListItemModel';
import { StudentInterventionModel } from '../../types/StudentInterventionModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchStudentInterventionById,
  fetchStudentInterventionFormViewModel,
  fetchStudentInterventionListItems,
} from '../thunks/studentInterventionThunks';

interface StudentInterventionState {
  schoolYear: string;
  loadingListItems: boolean;
  listItems: StudentInterventionListItemModel[];

  loadingStudentIntervention: boolean;
  studentIntervention: StudentInterventionModel;

  loadingFormViewModel: boolean;
  formViewModel: StudentInterventionFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultStudentInterventionState: StudentInterventionModel = {
  id: '',
  schoolYear: '',
  msisId: '',
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: '',
  ssn: '',
  districtName: '',
  districtId: '',
  schoolName: '',
  schoolId: '',
  grade: '',
  gender: '',
  ethinicity: '',
  race: '',
  sliIndicator: '',
  interventionCriteria: '',
  initialReferralInterventionDate: '',
  interventionReferralDate: '',
  interventionDecisionDate: '',
  numberOfSuspensionDays: '',
  tierPlacement: '',
  interventionSuccess: '',
  documentedReviewDate: '',
  cumulativeDocumentedReviewDate: '',
};

export const defaultFormState: StudentInterventionFormViewModel = {
  defaultSchoolYear: '',
};

export const defaultDataEntryMode = DataEntryMode.NEW;

export const initialState: StudentInterventionState = {
  schoolYear: '',
  loadingListItems: false,
  listItems: [],
  loadingStudentIntervention: false,
  studentIntervention: defaultStudentInterventionState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  dataEntryMode: defaultDataEntryMode,
};

export const studentInterventionSlice = createSlice({
  name: 'studentIntervention',
  initialState,
  reducers: {
    setSchoolYearFilter: (state, action: PayloadAction<string>) => {
      state.schoolYear = action.payload;
    },
    clearStudentIntervention: (state) => {
      state.studentIntervention = defaultStudentInterventionState;
    },
    saveStudentIntervention: (
      state,
      action: PayloadAction<StudentInterventionModel>
    ) => {
      state.studentIntervention = action.payload;
    },
    setStudentInterventionDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchStudentInterventionList
    builder.addCase(fetchStudentInterventionListItems.pending, (state) => {
      state.listItems = [];
      state.loadingListItems = true;
    });
    builder.addCase(
      fetchStudentInterventionListItems.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.listItems = action.payload;
        }
        state.loadingListItems = false;
      }
    );
    builder.addCase(
      fetchStudentInterventionListItems.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingListItems = false;
        }
      }
    );

    // fetchStudentInterventionById
    builder.addCase(fetchStudentInterventionById.pending, (state) => {
      state.studentIntervention = defaultStudentInterventionState;
      state.loadingStudentIntervention = true;
    });
    builder.addCase(fetchStudentInterventionById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.studentIntervention = action.payload;
      }
      state.loadingStudentIntervention = false;
    });
    builder.addCase(fetchStudentInterventionById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingStudentIntervention = false;
      }
    });

    //fetchStudentInterventionFormViewModel
    builder.addCase(fetchStudentInterventionFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchStudentInterventionFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchStudentInterventionFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );
  },
});

export const {
  setSchoolYearFilter,
  clearStudentIntervention,
  saveStudentIntervention,
  setStudentInterventionDataEntryMode,
} = studentInterventionSlice.actions;

export const selectSchoolYear = (state: RootState): string =>
  state.studentIntervention.schoolYear;
// listItems
export const selectStudentInterventionLoadingListItems = (
  state: RootState
): boolean => state.studentIntervention.loadingListItems;
export const selectStudentInterventionListItems = (
  state: RootState
): StudentInterventionListItemModel[] => state.studentIntervention.listItems;
// StudentIntervention
export const selectStudentInterventionLoadingStudentIntervention = (
  state: RootState
): boolean => state.studentIntervention.loadingStudentIntervention;
export const selectStudentInterventionStudentIntervention = (
  state: RootState
): StudentInterventionModel => state.studentIntervention.studentIntervention;

// formViewModel
export const selectStudentInterventionLoadingFormViewModel = (
  state: RootState
): boolean => state.studentIntervention.loadingFormViewModel;
export const selectStudentInterventionFormViewModel = (
  state: RootState
): StudentInterventionFormViewModel => state.studentIntervention.formViewModel;

// dataEntryMode
export const selectStudentInterventionDataEntryMode = (
  state: RootState
): DataEntryMode => state.studentIntervention.dataEntryMode;
export default studentInterventionSlice.reducer;
