import { BoardMembersFormViewModel } from 'src/types/BoardMembersFormViewModel';
import { BoardMembersListItemModel } from 'src/types/BoardMembersListItemModel';
import { ApiResponseModel } from '../types/ApiResponseModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const boardMembersApiPaths = {
  update: 'salaryScale',
  getListItems: (schoolYear: string) =>
    `salaryScale?calendarYear=${schoolYear}`,
  updateAllListItems: (schoolYear: string, additionalSalary: string) =>
    //eslint-disable-next-line
    `/salaryscale/adjustingamount?schoolYear=${schoolYear}&adjustingAmount=${additionalSalary}`,
  getForm: 'salaryscale/form',
  getMinSalaryListItems: (schoolYear: string) =>
    `salaryscale/getminimumsalaryschedulelist?calendarYear=${schoolYear}`,
};

export const BoardMembersServiceClient = (
  config?: DataRequestConfig
): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getBoardMembersListItems = async (
  schoolYear: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<BoardMembersListItemModel[]>> => {
  return executeApiRequest<BoardMembersListItemModel[]>(async () => {
    return await BoardMembersServiceClient(config).get(
      boardMembersApiPaths.getListItems(schoolYear)
    );
  });
};

export const getMinBoardMembersListItems = async (
  schoolYear: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<BoardMembersListItemModel[]>> => {
  return executeApiRequest<BoardMembersListItemModel[]>(async () => {
    return await BoardMembersServiceClient(config).get(
      boardMembersApiPaths.getMinSalaryListItems(schoolYear)
    );
  });
};

export const putAllBoardMembersListItems = async (
  schoolYear: string,
  additionalSalary: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<BoardMembersListItemModel[]>> => {
  return executeApiRequest<BoardMembersListItemModel[]>(async () => {
    return await BoardMembersServiceClient(config).put(
      boardMembersApiPaths.updateAllListItems(schoolYear, additionalSalary)
    );
  });
};

export const updateBoardMembers = async (
  body: BoardMembersListItemModel[],
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await BoardMembersServiceClient(config).put(
      boardMembersApiPaths.update,
      body
    );
  });
};

export const getBoardMembersFormViewModel = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<BoardMembersFormViewModel>> => {
  return executeApiRequest<BoardMembersFormViewModel>(async () => {
    return await BoardMembersServiceClient(config).get(
      boardMembersApiPaths.getForm
    );
  });
};
