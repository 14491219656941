/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { BoardMembersFormViewModel } from '../../types/BoardMembersFormViewModel';
import {
  BoardMembersInputModel,
  BoardMembersListItemModel,
} from '../../types/BoardMembersListItemModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchBoardMembersFormViewModel,
  fetchBoardMembersListItems,
  updateAllBoardMembersListItems,
} from '../thunks/boardMembersThunks';

interface BoardMembersState {
  schoolYear: string;
  loadingListItems: boolean;
  listItems: BoardMembersListItemModel[];
  dataEntryMode: DataEntryMode;
  updatedListItems: BoardMembersListItemModel[];
  loadingFormViewModel: boolean;
  formViewModel: BoardMembersFormViewModel;
  minSalarySchedule: BoardMembersListItemModel[];
}

export const defaultListState: BoardMembersListItemModel[] = [];

export const defaultBoardMembersState: BoardMembersListItemModel = {
  id: '',
  leaName: '',
  leaType: '',
  leaIdentifier: '',
  leaOperationalStatus: ''
};

export const defaultAdditionalBoardMembers: BoardMembersInputModel = {
  schoolYear: '2024-2025',
  additionalSalary: '$0.00',
};

export const defaultFormState: BoardMembersFormViewModel = {
  historicalSchoolYearOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: BoardMembersState = {
  schoolYear: '',
  loadingListItems: false,
  listItems: defaultListState,
  dataEntryMode: dataEntryMode,
  updatedListItems: defaultListState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  minSalarySchedule: defaultListState,
};

export const boardMembersSlice = createSlice({
  name: 'boardMembers',
  initialState,
  reducers: {
    setSchoolYearFilter: (state, action: PayloadAction<string>) => {
      state.schoolYear = action.payload;
    },
    setBoardMembersDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
    setUpdatedListItems: (
      state,
      action: PayloadAction<BoardMembersListItemModel>
    ) => {
      const data = action.payload;
      state.updatedListItems.push(data);
    },
    setDefaultUpdatedListItems: (state) => {
      state.updatedListItems = [];
    },
    setIsListItemLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingListItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchBoardMembersListItems
    builder.addCase(fetchBoardMembersListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(fetchBoardMembersListItems.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.listItems = action.payload;
      }
      state.loadingListItems = false;
    });
    builder.addCase(fetchBoardMembersListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    //updateAllBoardMembersListItems
    builder.addCase(updateAllBoardMembersListItems.pending, (state) => {
      state.loadingListItems = true;
    });
    builder.addCase(
      updateAllBoardMembersListItems.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.listItems = action.payload;
        }
      }
    );
    builder.addCase(updateAllBoardMembersListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchBoardMembersFormViewModel
    builder.addCase(fetchBoardMembersFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchBoardMembersFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
          state.schoolYear =
            action.payload.historicalSchoolYearOptions[1].value;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(fetchBoardMembersFormViewModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingFormViewModel = false;
      }
    });
  },
});

//export reducers
export const {
  setSchoolYearFilter,
  setUpdatedListItems,
  setDefaultUpdatedListItems,
  setIsListItemLoading,
} = boardMembersSlice.actions;

export const selectSchoolYear = (state: RootState): string =>
  state.boardMembers.schoolYear;
export const selectUpdatedListItems = (
  state: RootState
): BoardMembersListItemModel[] => state.boardMembers.updatedListItems;

export const selectBoardMembersLoadingListItems = (
  state: RootState
): boolean => state.boardMembers.loadingListItems;
export const selectBoardMembersListItems = (
  state: RootState
): BoardMembersListItemModel[] => state.leaManagement.listItems;

// dataEntryMode
export const selectBoardMembersDataEntryMode = (
  state: RootState
): DataEntryMode => state.salaryScale.dataEntryMode;

export default boardMembersSlice.reducer;
