/* eslint-disable max-len */
import { ReactElement } from 'react';
import StudentIntervention_StudentInterventionPanel from './StudentIntervention_StudentInterventionPanel';
import StudentIntervention_InterventionDetailsPanel from './StudentIntervention_InterventionDetailsPanel'

const StudentIntervention_Layout = (): ReactElement => {
  return (
    <>
      <StudentIntervention_StudentInterventionPanel />
      <StudentIntervention_InterventionDetailsPanel />
    </>
  );
};

export default StudentIntervention_Layout;
