/* eslint-disable max-len */
import { Menu, MenuItem, TablePagination } from '@mui/material';
import { saveAs } from 'file-saver';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import DataQualityFilter from '../../components/DataQualityFilter/DataQualityFilter';
import DataQualityRuleErrorList from '../../components/DataQualityRuleErrorList/DataQualityRuleErrorList';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import TabContainer from '../../components/TabContainer/TabContainer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectCategoryErrors,
  selectDataQualityLoading,
  selectDQDFilter,
} from '../../redux/slices/dataQualitySlice';
import { fetchDataQualityItemsForCategory } from '../../redux/thunks/dataQualityThunks';
import { abortPromiseOnUnmount } from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { DataQualityErrorsRequest } from '../../types/DataQualityDashboardRequest';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import './DataQualityDetail.css';

const normalizeErrorLevel = (level?: string): string => {
  switch (level) {
    case 'error':
      return 'Error';
    case 'warning':
      return 'Warning';
    default:
      return '';
  }
};

const getFormattedDate = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${year}${month}${day}`;
};

const DataQualityDetail = (): ReactElement => {
  const { category, errorLevel } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectDataQualityLoading);
  const filter = useAppSelector(selectDQDFilter);
  const details = useAppSelector(selectCategoryErrors);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [currTab, setCurrTab] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    let promise: unknown = undefined;
    if (category) {
      const params: DataQualityErrorsRequest = {
        domain: category,
        filter: filter,
        errorLevel: normalizeErrorLevel(errorLevel?.toLowerCase()),
      };
      promise = dispatch(fetchDataQualityItemsForCategory(params));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, category, filter, errorLevel]);

  const getExcelBuffer = (): BlobPart => {
    const workbook = XLSX.utils.book_new();

    details.tabs.forEach((rule) => {
      const sheetName = rule.displayName.substring(0, 31);

      const headers = rule.orderedHeaders.map((header) => header.displayName);
      const rows = rule.rowData.map((row) =>
        rule.orderedHeaders.map((header) => row[header.key] || '')
      );

      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    return excelBuffer;
  };

  const handleExport = (): void => {
    const errorLabel = errorLevel
      ? `${errorLevel[0].toUpperCase()}${errorLevel.slice(1)}s`
      : '';
    const userFileName = prompt(
      'Enter file name',
      `${details.domain} ${errorLabel} ${getFormattedDate()}`
    );
    handleCloseMenu();

    if (!userFileName) return;

    const excelBuffer = getExcelBuffer();
    const fileBlob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });
    saveAs(fileBlob, `${userFileName}.xlsx`);
  };

  const getTabDisplayNames = (): string[] =>
    details.tabs.map((tab) => tab.displayName);

  const isEmptyList = details.tabs.length === 0;

  const buildTabs = (): ReactNode[] => {
    return details.tabs.map((tab, index) => {
      return (
        <div
          key={`rule-${index}`}
          data-testid={`rule-${index}-tab-container`}
          className="data-quality-rule-container"
        >
          <DataQualityRuleErrorList
            headerColumns={tab.orderedHeaders}
            dataRows={tab.rowData.slice(
              page * limit,
              Math.min((page + 1) * limit, tab.rowData.length)
            )}
          />
        </div>
      );
    });
  };

  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (newLimit: number): void => {
    setLimit(newLimit);
    setPage(0);
  };
  const handleChangeTab = (newTab: number): void => {
    setCurrTab(newTab);
    setPage(0);
  };

  return (
    <NavigationPage
      heading={details.domain}
      pageClass="data-quality--detail"
      isLoading={isLoading}
      loadingDataId="data-quality-detail-loader"
      loadingText="Getting your details"
      backBarLocation={appRoutePaths.DataQualityDashboard}
    >
      <>
        {!isEmptyList && (
          <>
            <div className="data-quality-tools-container">
              <DataQualityFilter
                dataCollectionValue={filter}
                dataCollectionOptions={details.dataCollectionOptions}
              />
              <div className="right-side-tools">
                <TablePagination
                  data-testid="data-quality-pagination"
                  component="div"
                  count={details.tabs[currTab].rowData.length}
                  page={page}
                  onPageChange={(_e, newPage) => handleChangePage(newPage)}
                  rowsPerPage={limit}
                  onRowsPerPageChange={(e) =>
                    handleChangeRowsPerPage(Number(e.target.value))
                  }
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
                <div
                  data-testid="data-quality-utils"
                  className="three-dots"
                  onClick={handleOpenMenu}
                >
                  <span />
                </div>
              </div>
            </div>
            <TabContainer
              tab={currTab}
              handleChangeTab={handleChangeTab}
              tabNames={getTabDisplayNames()}
            >
              {buildTabs()}
            </TabContainer>
          </>
        )}
        {isEmptyList && (
          <EmptyGridContent iconType={IconType.FILE}>
            <p>
              There are no {errorLevel ? errorLevel.toLowerCase() : `error`}s
            </p>
          </EmptyGridContent>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'data-quality-tools-menu',
          }}
        >
          <MenuItem data-testid="data-quality-export" onClick={handleExport}>
            Export
          </MenuItem>
        </Menu>
      </>
    </NavigationPage>
  );
};

export default DataQualityDetail;
