import { orderBy } from 'lodash';
import { ReactElement, useEffect, useMemo } from 'react';
import { appRoutePaths } from 'src/services/route.service';
import Bubble from '../../components/Bubble/Bubble';
import DataQualityFilter from '../../components/DataQualityFilter/DataQualityFilter';
import EmptyGridContent from '../../components/EmptyGridContent/EmptyGridContent';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectDashboardItems,
  selectDataQualityLoading,
  selectDQDFilter,
} from '../../redux/slices/dataQualitySlice';
import { fetchDataQualityData } from '../../redux/thunks/dataQualityThunks';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
} from '../../services/base.service';
import { DataQualityDashboardModel } from '../../types/DataQualityDashboardModel';
import { IconType } from '../../types/propTypes/EmptyGridPropTypes';
import './DataQualityDashboard.css';
import React from 'react';

const DataQualityDashboard = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectDataQualityLoading);
  const filter = useAppSelector(selectDQDFilter);
  const items: DataQualityDashboardModel = useAppSelector(selectDashboardItems);

  useEffect(() => {
    const promise = dispatch(fetchDataQualityData({ filter }));
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, filter]);

  const sortedDisplayErrors = useMemo(
    () => orderBy(items.domainErrors, 'displayText', 'asc'),
    [items.domainErrors]
  );
  
  return (
    <NavigationPage
      pageClass="data-quality"
      heading={`${getCurrentUserAccess()?.currentDistrict?.name}`}
      isLoading={isLoading}
      loadingDataId="data-quality-loader"
      loadingText="Getting your items"
    >
      <DataQualityFilter
        dataCollectionValue={filter}
        dataCollectionOptions={items.dataCollectionOptions}
      />
      
      <div className="bubble-list">
        {sortedDisplayErrors.map((item, index) => (
          <>
            <React.Fragment key={item.domain + index}>
              {parseInt(item.errorCount) > 0 && (
                <Bubble
                  key={item.domain + '-error'}
                  count={item.errorCount}
                  title={item.displayText}
                  bubbleText="Error(s)"
                  navigateTo={appRoutePaths.DataQualityDetail(item.domain, 'error')}
                  visibilityOverrideRoute={appRoutePaths.DataQualityDetail()}
                />
              )}
              {parseInt(item.warningCount) > 0 && (
                <Bubble
                  key={item.domain + '-warning'}
                  count={item.warningCount}
                  title={item.displayText}
                  bubbleText="Warning(s)"
                  navigateTo={appRoutePaths.DataQualityDetail(item.domain, 'warning')}
                  visibilityOverrideRoute={appRoutePaths.DataQualityDetail()}
                  classConfig="warning"
                />
              )}
            </React.Fragment>
        </>
        ))}
      </div>

      {sortedDisplayErrors.length === 0 && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>There are no errors</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default DataQualityDashboard;
