/* eslint-disable max-len */
import { ReactElement } from 'react';
import FormCheckboxList from 'src/components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDropdown from 'src/components/FormInputs/FormDropdown/FormDropdown';
import FormRadioList from 'src/components/FormInputs/FormRadioList/FormRadioList';
import { PanelType } from '../../../types/PanelType';
import { StudentInterventionValidationSchema } from '../../../types/Validation/StudentInterventionValidationSchema';
import { getDaysUptoMaxLimit, isStateNonAdmin } from '../../../utilities/userUtilities';
import FormDateSelector from '../../FormInputs/FormDateSelector/FormDateSelector';
import Panel from '../../Panel/Panel';
import { FormRadioListOrientation } from 'src/types/propTypes/FormRadioListPropTypes';

const StudentIntervention_InterventionDetailsPanel = (): ReactElement => {
  return (
    <>
      <Panel panelType={PanelType.INFO} heading="Intervention Details">
        <div className="field-row field-row-panel">
          <div>
            <FormCheckboxList
              displayName="Intervention Criteria"
              field="interventionCriteria"
              options={[
                {
                  text: '*1 Grades K-3 any student that has failed 1 year',
                  value: '*1 Grades K-3 any student that has failed 1 year',
                },
                {
                  text: '*2 Grades 4-12 any student that has failed 2 years',
                  value: '*2 Grades 4-12 any student that has failed 2 years',
                },
                {
                  text: '*3 Any student who has failed one of the previous 2 years AND they have 20 days or more suspension in the current school year',
                  value:
                    '*3 Any student who has failed one of the previous 2 years AND they have 20 days or more suspension in the current school year',
                },
                {
                  text: '*4 Student scores at the lowest level on any part of the Grade 3 or Grade 7 statewide accountability assessment',
                  value:
                    '*4 Student scores at the lowest level on any part of the Grade 3 or Grade 7 statewide accountability assessment',
                },
                {
                  text: '*5 Student promoted from Grade 3 to Grade 4 under a good cause exemption of the Literacy-Based Promotion Act',
                  value:
                    '*5 Student promoted from Grade 3 to Grade 4 under a good cause exemption of the Literacy-Based Promotion Act',
                },
                { text: '*6 Referral Student', value: '*6 Referral Student' },
              ]}
              validationSchema={StudentInterventionValidationSchema}
              disabled={isStateNonAdmin()}
            />
          </div>
          <div className="panel-details">
            <FormDateSelector
              displayName="Initial Referral Intervention Date"
              field="initialReferralInterventionDate"
              validationSchema={StudentInterventionValidationSchema}
              disabled={isStateNonAdmin()}
            />
            <br />
            <FormDropdown
              displayName="Number of Suspension days"
              field="numberOfSuspensionDays"
              options={getDaysUptoMaxLimit(365)}
              validationSchema={StudentInterventionValidationSchema}
              disabled={isStateNonAdmin()}
            />
            <br />
            <FormRadioList
              displayName="Tier Placement"
              field="tierPlacement"
              options={[
                { text: 'Tier I', value: 'Tier I' },
                { text: 'Tier II', value: 'Tier II' },
                { text: 'Tier III', value: 'Tier III' },
              ]}
              optionOrientation={FormRadioListOrientation.Horizontal}
              validationSchema={StudentInterventionValidationSchema}
              disabled={isStateNonAdmin()}
            />
          </div>
        </div>

        <div className="field-row field-row-panel-details">
          <FormDateSelector
            displayName="Intervention Referral Date"
            field="interventionReferralDate"
            placeholderText="mm/dd/yyyy"
            disabled={isStateNonAdmin()}
          />
          <FormDateSelector
            displayName="Intervention Decision Date"
            field="interventionDecisionDate"
            placeholderText="mm/dd/yyyy"
            disabled={isStateNonAdmin()}
          />
        </div>
        <div className="field-row field-row-panel-details">
          <FormDropdown
            displayName="Number of Suspension days"
            field="numberOfSuspensionDays"
            options={[
              { text: 'Yes', value: 'Yes' },
              { text: 'No', value: 'No' },
              { text: 'Yes- Still Referring', value: 'Yes- Still Referring' },
              {
                text: 'NA- Student already receiving Special Education Services',
                value:
                  'NA- Student already receiving Special Education Services',
              },
            ]}
            validationSchema={StudentInterventionValidationSchema}
            disabled={isStateNonAdmin()}
          />
          <FormDateSelector
            displayName="Documented Review Date"
            field="documentedReviewDate"
            placeholderText="mm/dd/yyyy"
            disabled={isStateNonAdmin()}
          />
          <FormDateSelector
            displayName="Cumulative Documented Review Date"
            field="cumulativeDocumentedReviewDate"
            placeholderText="mm/dd/yyyy"
            disabled={isStateNonAdmin()}
          />
        </div>
      </Panel>
    </>
  );
};

export default StudentIntervention_InterventionDetailsPanel;
